import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../Services";
import { IHtmlContent } from "src/app/Models/Interfaces";
import { Flow } from "../../../../Models/Enums";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "../../../../AppStateManagement";
import { combineLatest, filter, map, takeUntil, tap } from "rxjs";
import { CompanyProfile, CompanyProfileRelationship } from "../../../../Models";
import { Product, ApplicationStatus } from "../../../../Models/Enums";

@Component({
	selector: "ls-begin-application",
	templateUrl: "./begin-application.component.html",
	styleUrls: ["./begin-application.component.scss"]
})
export class BeginApplicationComponent extends RouteStepDataBaseComponent {
	public data: BeginApplicationContent;

	constructor(
		public store: Store,
		public router: Router,
		public routeStepDataService: RouteStepDataService,
		private activatedRoute: ActivatedRoute,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors
	) {
		super(routeStepDataService);
		const htmlContent: any = this.activeRouteData!.htmlContent;

		this.data = new BeginApplicationContent(
			htmlContent.header,
			htmlContent.subHeader,
			htmlContent.expectations,
			htmlContent.subContent
		);
	}

	public companyProfile?: CompanyProfile;
	public companyProfileRelationships: CompanyProfileRelationship[] = [];
	public companyProfileRelationship?: CompanyProfileRelationship;
	private relationshipCompanyId?: number;

	ngOnInit() {
		const params = this.activatedRoute.snapshot.queryParams;
		if (params["invitationCode"]) {
			const invitationCode = atob(params["invitationCode"]);
			const [debtorCompanyId] = invitationCode.split(":");
			this.relationshipCompanyId = parseInt(debtorCompanyId, 10);

			const companyProfile$ = this.store
				.select(this.companyProfileSelectors.selectCompanyProfile)
				.pipe(filter((companyProfile) => !!companyProfile));

			const companyProfileRelationships$ = this.store
				.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships)
				.pipe(filter((companyProfileRelationships) => !!companyProfileRelationships));

			combineLatest([companyProfile$, companyProfileRelationships$])
				.pipe(
					takeUntil(this.componentTeardown$),
					map(([companyProfile, companyProfileRelationships]) => {
						this.companyProfile = companyProfile;
						this.companyProfileRelationships = companyProfileRelationships!.filter(
							(cpr) => cpr.productId === Product.SCF && cpr.relationshipCompanyId === this.relationshipCompanyId
						);

						if (this.companyProfileRelationships.length === 0 && this.relationshipCompanyId) {
							this.companyProfileRelationship = new CompanyProfileRelationship();
							this.companyProfileRelationship = {
								companyProfileId: this.companyProfile!.companyId,
								relationshipCompanyId: this.relationshipCompanyId!,
								productId: Product.SCF,
								applicationStatusId: ApplicationStatus.INPROGRESS
							};
							this.store.dispatch(
								CompanyProfileRelationshipActions.createCompanyProfileRelationship({
									companyProfileRelationship: this.companyProfileRelationship
								})
							);
						}
					})
				)
				.subscribe();
		}
	}

	onClick() {
		this.nav();
	}
}

class BeginApplicationContent implements IHtmlContent {
	public header: string;
	public subHeader: string;
	public expectations: Expectation[];
	public subContent?: string;

	constructor(header: string, subHeader: string, expectations: Expectation[], subContent?: string) {
		this.header = header;
		this.subHeader = subHeader;
		this.expectations = expectations;
		this.subContent = subContent;
	}
}

interface Expectation {
	icon: string;
	title: string;
	details: string;
}
