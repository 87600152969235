import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { mergeMap, withLatestFrom, filter, switchMap, Observable } from "rxjs";
import { StepperActions } from "./stepper-actions";
import { Action, Store } from "@ngrx/store";
import { StepperSelectors } from "./stepper-selectors";
import { LsStep } from "../../../Elements/stepper/limestone-element-stepper.component";
import { CompanyProfileActions, CompanyProfileSelectors, CompanyProfileRelationshipSelectors } from "..";
import { OnboardCompanyProgress } from "../../Models";
import { IRouteStepData } from "../../Models/Interfaces";
import { ActiveState, ChildStepType, StepperState } from "./stepper-reducer";

@Injectable()
export class StepperEffects {
	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private stepperSelectors: StepperSelectors,
		private companyProfileSelectors: CompanyProfileSelectors,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors
	) {}

	dispatchNextStep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StepperActions.setActiveRouteData),
			switchMap((act) => [StepperActions.setNextStep({})])
		)
	);

	setNextStep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StepperActions.setNextStep),
			withLatestFrom(
				this.store.select(this.stepperSelectors.selectState),
				this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
				this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship),
				this.store.select(this.companyProfileSelectors.selectCompanyProfile)
			),
			filter(
				([act, state, cpProgress, cpr, cp]) =>
					!!act && !!state && state.activeState !== undefined && !!cpProgress && !!cp
			),
			mergeMap(([act, state, cpProgress, cpr, cp]) => {
				let actions: Array<any> = new Array<any>();
				const customerSetupStep = state.steps.findIndex((s) => s.label === "Customer Setup");
				// update parent steps //
				const steps = state.steps;

				const activeStep = state.activeState?.activeStep ?? 0;
				const activeSubstep = state.activeState?.activeSubstep ?? 0;
				if (state.activeRouteStepData!.step! >= activeStep) {
					this.setPercentComplete(steps[activeStep], state.activeRouteStepData!.substep ?? cpProgress!.currentSubStep!);
				}

				if (state.activeRouteStepData!.step! > activeStep) this.updateStep(steps[activeStep]);

				if (state.steps[cpProgress!.currentStep ?? 0].displaySettings) {
					actions.push(StepperActions.setSettingsDisplay({ display: true }));
					state.displaySettings = true;
				}

				// update child steps (if needed)//
				if (state.activeRouteStepData!.section !== undefined && state.activeState?.childStepsActive) {
					let childSteps: LsStep[] = [];
					switch (state.activeState?.childStepType) {
						case ChildStepType.CUSTOMER_SETUP:
							childSteps = steps[customerSetupStep].children!;
							if (state.activeRouteStepData!.substep! > cpr!.currentSubStep!) {
								this.updateProgress(
									state.activeRouteStepData!.section,
									state.activeRouteStepData!.subSection!,
									childSteps,
									cpr!.currentSubStep!
								);
							}
					}
					state.steps = steps;
				}

				if (state.activeRouteStepData!.step === undefined) {
					state.activeRouteStepData! = {
						step: state.activeState?.activeStep,
						substep: state.activeState?.activeSubstep
					};
				}
				actions = actions.concat(this.getActionsToSynchronizeStepsAndActiveState(steps, state));

				// update company progress //
				if (
					(cpProgress!.currentStep || cpProgress!.currentStep === 0) &&
					(cpProgress!.currentSubStep || cpProgress!.currentSubStep === 0)
				) {
					if (
						state.activeRouteStepData!.step! > cpProgress!.currentStep ||
						(state.activeRouteStepData!.step! === cpProgress!.currentStep &&
							state.activeRouteStepData!.substep! > cpProgress!.currentSubStep)
					) {
						// Moving forward to new step/substep first time
						const progressObject: OnboardCompanyProgress = new OnboardCompanyProgress(
							cpProgress!.companyId,
							cpProgress!.isOnboardingComplete,
							state.activeRouteStepData!.step,
							state.activeRouteStepData!.substep,
							cpProgress!.onboardingConfiguration,
							cpProgress!.productId
						);
						actions.push(CompanyProfileActions.updateOnboardCompanyProgress({ progress: progressObject }));
					}
				} else {
					// First time updating the DB
					const progressObject: OnboardCompanyProgress = new OnboardCompanyProgress(
						cpProgress!.companyId,
						cpProgress!.isOnboardingComplete,
						state.activeRouteStepData!.step,
						state.activeRouteStepData!.substep,
						cpProgress!.onboardingConfiguration,
						cpProgress!.productId
					);
					actions.push(CompanyProfileActions.updateOnboardCompanyProgress({ progress: progressObject }));
				}
				return actions;
			})
		)
	);

	initializeStepper$ = createEffect(
		(): Observable<Action> =>
			this.actions$.pipe(
				ofType(StepperActions.initializeStepper),
				withLatestFrom(
					this.store.select(this.stepperSelectors.selectState),
					this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
					this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
				),
				filter(([act, state, cpProgress, cpr]) => !!state && !!cpProgress),
				mergeMap(([act, state, cpProgress, cpr]) => {
					let actions: Array<any> = new Array<any>();
					const customerSetupStep = state.steps.findIndex((s) => s.label === "Customer Setup");
					if (!state.activeState) {
						const steps = state.steps;
						const currentStep = cpProgress?.currentStep ?? 0;
						let currentSubStep = cpProgress?.currentSubStep;
						if (!!currentStep && state.steps[currentStep].displaySettings) {
							actions.push(StepperActions.setSettingsDisplay({ display: true }));
							state.displaySettings = true;
						}

						if (currentStep === customerSetupStep && cpr?.currentSubStep) {
							currentSubStep = cpr!.currentSubStep;
						}

						for (let i = 0; i <= currentStep!; i++) {
							if (currentStep! > i) {
								// this step is fully complete
								this.updateStep(steps[i]);
							} else {
								// this is the current step, set percentage
								this.setPercentComplete(steps[i], currentSubStep!);
							}
						}
						if (state.activeRouteStepData?.step === undefined) {
							state.activeRouteStepData = {
								step: currentStep,
								substep: currentSubStep
							};
						}
						actions = actions.concat(this.getActionsToSynchronizeStepsAndActiveState(steps, state));
					}

					return actions;
				})
			)
	);

	initializeChildSteps$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StepperActions.initializeChildSteps),
			withLatestFrom(
				this.store.select(this.stepperSelectors.selectState),
				this.store.select(this.companyProfileSelectors.selectCompanyProfile),
				this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			),
			mergeMap(([act, state, cp, cpr]) => {
				const actions: Array<any> = new Array<any>();
				const steps = state.steps;
				let childSteps: LsStep[] = [];
				const childProgress = cpr!.currentSubStep!;
				let progressData: IRouteStepData;
				switch (act.childStepType) {
					case ChildStepType.CUSTOMER_SETUP:
						const customerSetupStep = steps.findIndex((s) => s.label === "Customer Setup");
						//No customer setup in SCF?
						if (customerSetupStep !== -1) {
							progressData = this.getRouteStepData(state.routeStepData!, customerSetupStep, cpr!.currentSubStep!);
							childSteps = steps[customerSetupStep].children!;
							this.resetChildProgress(childSteps);
							if (childProgress === steps[customerSetupStep].substeps) {
								const lastChild = childSteps[childSteps.length - 1];
								const secondLastChild = childSteps[childSteps.length - 2];

								this.updateStep(secondLastChild);
								this.updateStep(lastChild);
							}
						}
				}
				this.updateProgress(progressData!.section!, progressData!.subSection!, childSteps, childProgress, true);

				const completedSteps = this.updateCompletedSteps(steps);
				actions.push(
					StepperActions.updateSteps({ steps }),
					StepperActions.setActiveState({
						activeState: this.configureActiveState(
							state.activeRouteStepData!,
							steps,
							state.displaySettings,
							completedSteps
						)
					})
				);

				return actions;
			})
		)
	);

	private getActionsToSynchronizeStepsAndActiveState(steps: LsStep[], state: StepperState): Action[] {
		const completedSteps = this.updateCompletedSteps(steps);

		return [
			StepperActions.updateSteps({ steps }),
			StepperActions.setCompletedSteps({ steps: completedSteps }),
			StepperActions.setActiveState({
				activeState: this.configureActiveState(state.activeRouteStepData!, steps, state.displaySettings, completedSteps)
			})
		];
	}

	private configureActiveState(
		routeData: IRouteStepData,
		steps: LsStep[],
		displaySettings: boolean,
		completedSteps: Set<number>
	) {
		let activeState: ActiveState;
		if (
			routeData.section !== undefined &&
			steps[routeData.step!].childDisplayStep &&
			steps[routeData.step!].childDisplayStep! <= routeData.substep!
		) {
			const childSteps = steps[routeData.step!].children;
			const completedChildSteps = new Set<number>();
			childSteps
				?.filter((s, indx) => childSteps[indx - 1] !== undefined && childSteps[indx - 1].percentComplete === 100)
				.forEach((s, indx) => completedChildSteps.add(indx));
			activeState = {
				activeSteps: childSteps!,
				activeStep: routeData.section,
				activeSubstep: routeData.subSection!,
				activeCompletedSteps: completedSteps,
				childStepsActive: true,
				childStepType: childSteps![routeData.step!].childStepType,
				displaySettingsButton: displaySettings,
				displayNavBackButton: true,
				navBackName: childSteps![routeData.step!].label,
				parent: {
					activeSteps: steps,
					activeStep: routeData.step!,
					activeSubstep: routeData.substep!,
					activeCompletedSteps: completedSteps,
					childStepsActive: false,
					displaySettingsButton: displaySettings,
					displayNavBackButton: false
				}
			};
		} else {
			activeState = {
				activeSteps: steps,
				activeStep: routeData.step!,
				activeSubstep: routeData.substep!,
				activeCompletedSteps: completedSteps,
				childStepsActive: false,
				displaySettingsButton: displaySettings,
				displayNavBackButton: false
			};
		}

		return activeState;
	}

	private updateCompletedSteps(steps: LsStep[]): Set<number> {
		const stepsComplete = new Set<number>();
		steps
			.filter((step, indx) => step.percentComplete === 100)
			.map((step, indx) => {
				stepsComplete.add(indx);
			});
		return stepsComplete;
	}

	private setPercentComplete(step: LsStep, activeSubStep: number): void {
		if (step.percentComplete !== 100) {
			step.completedSubsteps = activeSubStep;
			step.percentComplete = (step.completedSubsteps / (step.substeps ?? 1)) * 100;
		}
	}

	private updateStep(previousStep: LsStep) {
		previousStep.completedSubsteps = previousStep.substeps;
		previousStep.percentComplete = 100;
	}

	private getRouteStepData(
		routeStepData: Map<string, IRouteStepData>,
		stepVal: number,
		substepVal: number
	): IRouteStepData {
		return [...routeStepData.values()].filter(
			(routeStepData) => routeStepData.step === stepVal && routeStepData.substep === substepVal
		)[0];
	}

	private resetChildProgress(childSteps: LsStep[]) {
		childSteps.forEach((step) => {
			if (!step.childStepAlwaysComplete) {
				step.completedSubsteps = 0;
				step.percentComplete = 0;
			}
		});
	}

	private updateProgress(
		progressStep: number,
		progressSubStep: number,
		childSteps: LsStep[],
		childProgress: number,
		isInit = false
	) {
		for (let i = isInit ? 0 : progressStep - 1; i <= progressStep; i++) {
			if (progressStep > i) {
				this.updateStep(childSteps[i]);
			} else {
				this.setPercentComplete(childSteps[i], Math.min(progressSubStep!, childProgress));
			}
			childProgress = Math.max(childProgress - childSteps[i].substeps, 0);
		}
	}
}
