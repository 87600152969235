import { ISFChatbotConfig, ISFChatbot } from "src/app/Models/Interfaces";
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from "./b2c-configuration";

const scopes = ["https://raistoneb2c.onmicrosoft.com/api-ls-qa2/user_impersonation"];

const salesforceChatbotConfig: ISFChatbotConfig = {
	baseLiveAgentContentURL: "https://c.la2s-core1.sfdc-lywfpd.salesforceliveagent.com/content",
	deploymentId: "5721I0000001L5g",
	buttonId: "573Qi00000045CP",
	baseLiveAgentURL: "https://d.la2s-core1.sfdc-lywfpd.salesforceliveagent.com/chat",
	eswLiveAgentDevName: "Customer_Support",
	isOfflineSupportEnabled: false
};

const saleforceChatbot: ISFChatbot = {
	url: "https://raistone--qa.sandbox.my.salesforce.com",
	siteUrl: "https://raistone--qa.sandbox.my.site.com/",
	serviceUrl: "https://service.force.com",
	id: "00DEm000005QfRF",
	eswLiveAgentDevName: "Customer_Support",
	config: salesforceChatbotConfig
};
export const environment = {
	production: false,
	scopes,
	limestoneHost: "https://qa2.backend.raistoneapi.com",
	MsalInstanceConfig: MSALInstanceFactory("c72d940a-78ea-415f-b9cb-7e5637243a25", "B2C_1A_ALD_QA2"),
	MsalInterceptorConfig: MSALInterceptorConfigFactory("https://qa2.backend.raistoneapi.com", scopes),
	MsalGuardConfig: MSALGuardConfigFactory(scopes),
	pendoEnabled: true,
	environmentName: "QA2",
	supportEmailAddress: " QA-Support@raistone.com",
	salesforceChatbot: saleforceChatbot
};
