import { StepperConfiguration } from "./StepperConfiguration";

export class OnboardCompanyProgress {
	companyId: number;
	currentStep?: number;
	currentSubStep?: number;
	isOnboardingComplete: boolean;
	productId?: string;
	onboardingConfiguration?: StepperConfiguration;
	constructor(
		companyId: number,
		isOnboardingComplete: boolean,
		currentStep?: number,
		currentSubStep?: number,
		onboardingConfiguration?: any,
		productId?: string
	) {
		this.companyId = companyId;
		this.currentStep = currentStep;
		this.currentSubStep = currentSubStep;
		this.isOnboardingComplete = isOnboardingComplete;
		this.productId = productId;
		this.onboardingConfiguration = new StepperConfiguration(
			onboardingConfiguration?.id,
			onboardingConfiguration?.description,
			onboardingConfiguration?.routeStepConfig,
			onboardingConfiguration?.stepConfig
		);
	}
}
