import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from "rxjs";
import { HttpErrorService } from "src/services/HTTP";
import { CompanyProfileActions } from "./company-profile-actions";
import { HttpErrorResponse } from "@angular/common/http";
import { LsHttpErrorResponse } from "src/app/Models";
import { Store } from "@ngrx/store";
import { CompanyProfileSelectors } from "./company-profile-selectors";
import { CompanyProfileService, CompanyProgressService } from "../../Modules/COT-Module/Services";
import { CompanyLsUserService } from "../../../services/company-ls-user.service";
import copy from "fast-copy";
import { StepperActions } from "..";

@Injectable()
export class CompanyProfileEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private companyProfileSelectors: CompanyProfileSelectors,
		private companyProfileService: CompanyProfileService,
		private httpErrorService: HttpErrorService,
		private companyProgressService: CompanyProgressService,
		private companyLsUserService: CompanyLsUserService
	) {}

	getCompanyProfile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileActions.getCompanyProfile),
			mergeMap((act) =>
				this.companyProfileService
					.getCompanyProfile(act.companyId)
					.pipe(map((company) => CompanyProfileActions.setCompanyProfile({ companyProfile: company })))
			)
		)
	);

	updateCompanyProfile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileActions.updateCompanyProfile),
			mergeMap((act) =>
				this.companyProfileService.updateCompanyProfile(act.companyProfile).pipe(
					switchMap((cp) => [
						CompanyProfileActions.companyProfileSaveSuccessful(),
						CompanyProfileActions.setCompanyProfile({ companyProfile: cp })
					]),
					catchError((err) => {
						console.error(err);
						return this.httpErrorService
							.parseError(err)
							.pipe(map((resp) => CompanyProfileActions.companyProfileSaveUnsuccessful({ errors: resp })));
					})
				)
			)
		)
	);

	getCompanyProfileUsers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileActions.getCompanyProfileUsers),
			withLatestFrom(this.store.select(this.companyProfileSelectors.selectCompanyProfile)),
			mergeMap(([act, cp]) =>
				this.companyLsUserService.getCompanyProfileUsers(act.companyId, act.pr).pipe(
					map((users) => {
						return CompanyProfileActions.setCompanyProfileUsers({ users });
					})
				)
			)
		)
	);

	updateCompanyProfileUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileActions.updateCompanyProfileUser),
			mergeMap((act) =>
				this.companyProfileService.updateCompanyProfileUser(act.user).pipe(
					switchMap(() => [CompanyProfileActions.companyProfileUserSaveSuccessful()]),
					catchError((error: HttpErrorResponse) => {
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [CompanyProfileActions.companyProfileUserSaveUnsuccessful({ error: errResp })];
					})
				)
			)
		)
	);

	deleteCompanyProfileUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileActions.deleteCompanyProfileUser),
			withLatestFrom(this.store.select(this.companyProfileSelectors.selectCompanyProfile)),
			mergeMap(([act, cp]) => {
				cp!.users = cp!.users!.filter((u) => u.userId !== act.user.userId);
				return this.companyProfileService.deleteCompanyProfileUser(act.user).pipe(
					switchMap(() => [
						CompanyProfileActions.setCompanyProfile({ companyProfile: cp! }),
						CompanyProfileActions.companyProfileUserDeleteSuccessful({ user: act.user })
					]),
					catchError((error: HttpErrorResponse) => {
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [CompanyProfileActions.companyProfileUserSaveUnsuccessful({ error: errResp })];
					})
				);
			})
		)
	);

	createLeadForCompanyProfile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileActions.createLeadForCompanyProfile),
			mergeMap((action) =>
				this.companyProfileService
					.createLeadForCompanyProfile(action.companyId, action.flowId)
					.pipe(map(() => CompanyProfileActions.createLeadForCompanyProfileSuccess()))
			)
		)
	);

	inviteCompanyProfileUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileActions.inviteCompanyProfileUser),
			withLatestFrom(this.store.select(this.companyProfileSelectors.selectCompanyProfile)),
			mergeMap(([act, cp]) => {
				return this.companyLsUserService.inviteCompanyProfileUser(act.user).pipe(
					switchMap(() => [
						CompanyProfileActions.getCompanyProfile({ companyId: cp!.companyId! }),
						CompanyProfileActions.companyProfileUserInviteSuccessful({ user: act.user })
					]),
					catchError((error: HttpErrorResponse) => {
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [CompanyProfileActions.companyProfileUserSaveUnsuccessful({ error: errResp })];
					})
				);
			})
		)
	);

	resendInviteCompanyProfileUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileActions.resendInviteCompanyProfileUser),
			mergeMap((act) => {
				return this.companyLsUserService.resendInviteCompanyProfileUser(act.user).pipe(
					withLatestFrom(this.store.select(this.companyProfileSelectors.selectCompanyProfile)),
					switchMap(([user, cp]) => {
						const invitedUser = cp?.users?.find((u) => u.userId === user.userId);
						if (invitedUser) invitedUser!.lastInviteSent = user.lastInviteSent;
						return [
							CompanyProfileActions.setCompanyProfile({ companyProfile: copy(cp!) }),
							CompanyProfileActions.companyProfileUserInviteSuccessful({ user: act.user })
						];
					}),
					catchError((error: HttpErrorResponse) => {
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [CompanyProfileActions.companyProfileUserSaveUnsuccessful({ error: errResp })];
					})
				);
			})
		)
	);

	fetchOnboardProgress$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileActions.getOnboardCompanyProgress),
			switchMap((action) =>
				this.companyProgressService.getOnboardCompanyProgress(action.companyId).pipe(
					switchMap((progress) => {
						const routeData = progress.onboardingConfiguration!.routeStepData!.get(action.routeKey)!;
						return [
							CompanyProfileActions.setOnboardCompanyProgress({ progress: progress }),
							StepperActions.updateSteps({ steps: progress.onboardingConfiguration!.steps }),
							StepperActions.setRouteStepConfiguration({ data: progress!.onboardingConfiguration!.routeStepData! }),
							StepperActions.setActiveRouteData({ routeData }),
							StepperActions.initializeStepper()
						];
					})
				)
			)
		)
	);

	updateOnboardProgress$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileActions.updateOnboardCompanyProgress),
			switchMap((progress) =>
				this.companyProgressService
					.updateOnboardCompanyProgress(progress.progress)
					.pipe(switchMap((progress) => [CompanyProfileActions.setOnboardCompanyProgress({ progress: progress })]))
			)
		)
	);
}
