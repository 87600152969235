import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { map, takeUntil } from "rxjs/operators";
import { combineLatest, filter, Subject, take } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import { CompanyProfileRelationship } from "../../../../Models";
import { QuestionnaireComponent } from "../../../../Core";
import { CompanyProfileRelationshipActions, CompanyProfileSelectors } from "src/app/AppStateManagement";
import { Breadcrumb } from "../../../../Models/Interfaces/Breadcrumb";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../Services";

@Component({ selector: "ls-cpr", template: "" })
export abstract class CustomerRelationshipBaseComponent extends RouteStepDataBaseComponent implements OnDestroy {
	public componentTeardown$ = new Subject();
	companyProfileRelationship?: CompanyProfileRelationship;
	public breadcrumbs?: Array<Breadcrumb> = [];
	childForm?: QuestionnaireComponent;

	protected constructor(
		public router: Router,
		public store: Store,
		public actions$: Actions,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileRelationshipActions.companyProfileRelationshipSaveSuccessful),
				filter(() => this.companyProfileRelationship?.relationshipCompanyId !== undefined),
				map(() => {
					if (this.activeRouteData!.substep! > this.companyProfileRelationship!.currentSubStep!) {
						this.companyProfileRelationship!.currentSubStep = this.activeRouteData!.substep!;
						this.store.dispatch(
							CompanyProfileRelationshipActions.updateCustomerProgress({
								companyProfileRelationship: this.companyProfileRelationship!
							})
						);
					}
					this.navigateToNextScreen();
					this.childForm!.isNextButtonDisabled = false;
				})
			)
			.subscribe();

		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress)
		])
			.pipe(
				filter(([cp, ocp]) => !!cp && !!ocp),
				takeUntil(this.componentTeardown$),
				map(([cp, ocp]) => {
					const path = cp?.isCustomersInProgress ? "review" : "manual";
					this.breadcrumbs = [
						{
							routerPath: `/onboarding/${ocp!.onboardingConfigurationId}/customer-setup/${path}`,
							displayText: "Customer Setup"
						}
					];
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileRelationshipActions.companyProfileRelationshipSaveUnsuccessful),
				map(() => {
					this.childForm!.isNextButtonDisabled = false;
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public abstract continue(): void;
	public addCustomerNameToBreadCrumbs(name?: string) {
		if (this.breadcrumbs && this.breadcrumbs.length < 2 && name !== null && name !== undefined && name?.trim() !== "")
			this.breadcrumbs.push({ routerPath: "", displayText: name! });
	}
	public navigateToNextScreen(index = 0) {
		if (
			this.activeRouteData?.isCustomerSetupCompleteStep &&
			!this.companyProfileRelationship!.isCustomerSetupComplete
		) {
			this.companyProfileRelationship!.isCustomerSetupComplete = true;

			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: this.companyProfileRelationship!
				})
			);
		}
		this.nav(
			index,
			new Map<string, string | number>([
				["relationshipCompanyId", this.companyProfileRelationship!.relationshipCompanyId!]
			])
		);
	}

	public abstract initData(): void;
}
