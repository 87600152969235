import { Injectable } from "@angular/core";
import { FileHandle } from "src/Elements/upload-widget/FileHandle";

@Injectable()
export class FileUtilityService {
	public static convertFileSize(size: number): string {
		const sizes: string[] = ["B", "KB", "MB", "GB", "TB"];
		let order = 0;
		let len = size;
		while (len >= 1024 && order < sizes.length - 1) {
			order++;
			len = len / 1024;
		}
		return `${Math.round(len)} ${sizes[order]}`;
	}

	public static convertMegaBytesToBytes(megabytes: number): number {
		return megabytes * 1048576;
	}

	public static fileTypeSupported(file: FileHandle, allowedFileExtensions: string[]): boolean {
		const fileType = this.getFileType(file);
		const supported = allowedFileExtensions.includes(fileType!.toUpperCase());
		return supported;
	}

	public static getFileType(file: FileHandle): string {
		return file.file.name.split(/[.]+/).pop()!;
	}

	public static isFileSizeOverLimit(file: FileHandle, maxFileSize: number): boolean {
		const maxBytes = this.convertMegaBytesToBytes(maxFileSize);
		console.log("maxBytes " + maxBytes);
		console.log("file Size " + file.file.size);
		const overLimit: boolean = file.file.size > maxBytes ? true : false;
		return overLimit;
	}
}
