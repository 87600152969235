import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CompanyResolverService } from "../Modules/COT-Module/Services";

@Injectable()
export class CompanyProfileResolver {
	constructor(private companyResolverService: CompanyResolverService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		return this.companyResolverService.resolve(route, state);
	}
}
