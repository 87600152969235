import { createAction, props } from "@ngrx/store";
import { ActionType, LsAction } from "src/app/Core/LsAction";
import { CompanyProfile, CompanyUser, LsHttpErrorResponse, OnboardCompanyProgress } from "src/app/Models";
import { PageRequest } from "@limestone/ls-shared-modules";
import { Flow } from "src/app/Models/Enums";

export abstract class CompanyProfileActions extends LsAction {
	/* eslint-disable */
	static COMPANY_PROFILE: Readonly<string> = 'COMPANY_PROFILE';
	static COMPANY_PROFILE_CUSTOMER_IN_PROGRESS: Readonly<string> = 'COMPANY_PROFILE_CUSTOMER_IN_PROGRESS';
	static COMPANY_PROFILE_USER: Readonly<string> = 'COMPANY_PROFILE_USER';
	static COMPANY_PROFILE_USER_RESEND_INVITE: Readonly<string> = 'COMPANY_PROFILE_USER_RESEND_INVITE';
	static COMPANY_PROFILE_USER_DELETED: Readonly<string> = 'COMPANY_PROFILE_USER_DELETED';
	static COMPANY_PROFILE_USER_INVITED: Readonly<string> = 'COMPANY_PROFILE_USER_INVITED';
	static ONBOARD_PROGRESS: Readonly<string> = 'ONBOARD_PROGRESS';
	static COMPANY_PROFILE_LEAD: Readonly<string> = 'COMPANY_PROFILE_LEAD';
	/* eslint-enable */

	static getCompanyProfile = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE, ActionType.GET),
		props<{ companyId: number }>()
	);

	static setCompanyProfile = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE, ActionType.SET),
		props<{ companyProfile: CompanyProfile }>()
	);

	static updateCompanyProfile = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE, ActionType.UPDATE),
		props<{ companyProfile: CompanyProfile }>()
	);

	static companyProfileSaveSuccessful = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE, ActionType.COMPLETE)
	);

	static companyProfileSaveUnsuccessful = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE, ActionType.ERROR),
		props<{ errors: string[] }>()
	);

	static getCompanyProfileUsers = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_USER, ActionType.GET),
		props<{ companyId: number; pr: PageRequest }>()
	);
	static setCompanyProfileUsers = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_USER, ActionType.SET),
		props<{ users: CompanyUser[] }>()
	);
	static updateCompanyProfileUser = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_USER, ActionType.UPDATE),
		props<{ user: CompanyUser }>()
	);
	static deleteCompanyProfileUser = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_USER, ActionType.DELETE),
		props<{ user: CompanyUser }>()
	);
	static inviteCompanyProfileUser = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_USER, ActionType.TRIGGER),
		props<{ user: CompanyUser }>()
	);
	static resendInviteCompanyProfileUser = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_USER_RESEND_INVITE, ActionType.TRIGGER),
		props<{ user: CompanyUser }>()
	);
	static companyProfileUserSaveSuccessful = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_USER, ActionType.COMPLETE)
	);
	static companyProfileUserDeleteSuccessful = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_USER_DELETED, ActionType.COMPLETE),
		props<{ user: CompanyUser }>()
	);
	static companyProfileUserInviteSuccessful = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_USER_INVITED, ActionType.COMPLETE),
		props<{ user: CompanyUser }>()
	);

	static companyProfileUserSaveUnsuccessful = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_USER, ActionType.ERROR),
		props<{ error: LsHttpErrorResponse }>()
	);
	static setIsCustomersInProgress = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_CUSTOMER_IN_PROGRESS, ActionType.SET)
	);
	static getOnboardCompanyProgress = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.ONBOARD_PROGRESS, ActionType.GET),
		props<{ companyId: number; routeKey: string }>()
	);
	static setOnboardCompanyProgress = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.ONBOARD_PROGRESS, ActionType.SET),
		props<{ progress: OnboardCompanyProgress }>()
	);
	static updateOnboardCompanyProgress = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.ONBOARD_PROGRESS, ActionType.UPDATE),
		props<{ progress: OnboardCompanyProgress }>()
	);
	static createLeadForCompanyProfile = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_LEAD, ActionType.SAVE),
		props<{ companyId: number | undefined; flowId: Flow }>()
	);
	static createLeadForCompanyProfileSuccess = createAction(
		CompanyProfileActions.getActionName(CompanyProfileActions.COMPANY_PROFILE_LEAD, ActionType.COMPLETE)
	);
}
