import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { combineLatest, filter, map, Subject, takeUntil } from "rxjs";
import { CompanyProfile, UserInfo } from "../../../../Models";
import { ContinueApplicationService } from "../../../../../services";
import { Store } from "@ngrx/store";
import { AppSelectors, CompanyProfileSelectors } from "../../../../AppStateManagement";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../Services";

@Component({
	selector: "ls-resume-application",
	templateUrl: "./resume-application.component.html",
	styleUrls: ["./resume-application.component.scss"]
})
export class ResumeApplicationComponent extends RouteStepDataBaseComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	constructor(
		public router: Router,
		private continueApplicationService: ContinueApplicationService,
		public store: Store,
		private companyProfileSelectors: CompanyProfileSelectors,
		private appSelectors: AppSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
	}

	public companyProfile?: CompanyProfile;
	public userInfo?: UserInfo;

	ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.appSelectors.selectUserInfo)
		])
			.pipe(
				takeUntil(this.componentTeardown$),
				filter(([cp, info]) => !!cp && !!info),
				map(([companyProfile, info]) => {
					this.companyProfile = companyProfile;
					this.userInfo = info;
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	returnToBeginning() {
		if (!this.companyProfile!.isAccountingPlatformConnected) {
			this.nav(0);
		} else if (!this.companyProfile!.isBankingPlatformConnected) {
			this.nav(1);
		} else {
			this.nav(2);
		}
	}

	continueApplication() {
		this.continueApplicationService.continueApplication("../onboarding");
	}
}
