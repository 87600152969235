import { Action, createReducer, on } from "@ngrx/store";
import { LsStep } from "../../../Elements/stepper/limestone-element-stepper.component";
import { StepperActions } from "./stepper-actions";
import copy from "fast-copy";
import { IRouteStepData } from "src/app/Models/Interfaces";

export interface ActiveState {
	activeSteps: LsStep[];
	activeStep: number;
	activeSubstep: number;
	activeCompletedSteps: Set<number>;
	childStepsActive: boolean;
	displaySettingsButton: boolean;
	displayNavBackButton: boolean;
	navBackName?: string;
	parent?: ActiveState;
	childStepType?: ChildStepType;
}

export interface StepperState {
	steps: LsStep[];
	completedSteps: Set<number>;
	displaySettings: boolean;
	activeState?: ActiveState;
	routeStepData?: Map<string, IRouteStepData>;
	activeRouteStepData?: IRouteStepData;
}

export enum ChildStepType {
	CUSTOMER_SETUP = "CUSTOMER_SETUP"
}

export const initialState: StepperState = {
	steps: [],
	displaySettings: false,
	completedSteps: new Set<number>()
};

abstract class StepperReducerHandler {
	static appReducer = createReducer(
		initialState,
		on(StepperActions.updateSteps, (state: StepperState, { steps }) => ({
			...state,
			steps: copy(steps)
		})),
		on(StepperActions.setSettingsDisplay, (state: StepperState, { display }) => ({
			...state,
			displaySettings: display
		})),
		on(StepperActions.setCompletedSteps, (state: StepperState, { steps }) => ({
			...state,
			completedSteps: steps
		})),
		on(StepperActions.setActiveState, (state: StepperState, { activeState }) => ({
			...state,
			activeState
		})),
		on(StepperActions.setRouteStepConfiguration, (state: StepperState, { data }) => ({
			...state,
			routeStepData: data
		})),
		on(StepperActions.setActiveRouteData, (state: StepperState, { routeData }) => ({
			...state,
			activeRouteStepData: routeData
		}))
	);
}

export abstract class StepperReducer {
	static reducer = (state: StepperState | undefined, action: Action) => {
		return StepperReducerHandler.appReducer(state, action);
	};
}
