import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { filter, map, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { CompanyProfileRelationship } from "../../../../../Models";
import copy from "fast-copy";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-expedited-financing",
	templateUrl: "./expedited-financing.component.html",
	styleUrls: ["./expedited-financing.component.scss"]
})
export class ExpeditedFinancingComponent extends CustomerRelationshipBaseComponent {
	companyProfileRelationship?: CompanyProfileRelationship;
	toggleLabel = "Expedited Financing";
	private _isFinanceExpedited = false;
	toggledState = "No";
	get isFinanceExpedited() {
		return this._isFinanceExpedited;
	}
	set isFinanceExpedited(isFinanceExpedited: boolean) {
		this._isFinanceExpedited = isFinanceExpedited;
		this.toggledState = isFinanceExpedited ? "Yes" : "No";
	}
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService);
		this.initData();
	}

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				takeUntil(this.componentTeardown$),
				filter((cpr) => !!cpr),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					this.isFinanceExpedited = cpr!.isFinanceExpedited!;
					this.addCustomerNameToBreadCrumbs(cpr?.relationshipCompanyName);
				})
			)
			.subscribe();
	}

	continue() {
		if (this.isFinanceExpedited != this.companyProfileRelationship?.isFinanceExpedited) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.isFinanceExpedited = this.isFinanceExpedited;

			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
}
