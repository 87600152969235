import { Component, OnDestroy, OnInit } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { filter, map, takeUntil } from "rxjs/operators";
import { AccountInfo, EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";
import { Subject } from "rxjs";
import { LsUserRole, UserInfo } from "./Models";
import { Store } from "@ngrx/store";
import { AppActions, AppSelectors } from "./AppStateManagement";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { FeatureFlagService, FeatureFlags } from "@limestone/ls-shared-modules";

declare const embedded_svc: any;
@Component({
	selector: "ls-app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
	private componentTeardown$: Subject<any> = new Subject<any>();

	constructor(
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private store: Store,
		private router: Router,
		private featureFlagService: FeatureFlagService,
		private appSelectors: AppSelectors
	) {
		this.store.dispatch(AppActions.getUserIP());
		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this.componentTeardown$)
			)
			.subscribe(() => {
				if (this.authService.instance.getAllAccounts().length > 0) {
					const accounts = this.authService.instance.getAllAccounts();
					const lsAccount = accounts.find((acc) => {
						if (acc.idTokenClaims) {
							const userRoles = acc.idTokenClaims["extension_LsUserRoles"] as string;
							return userRoles.length > 0;
						}
						return false;
					});
					if (!lsAccount) throw new Error("No Raistone account found.");
					this.authService.instance.setActiveAccount(lsAccount);
					const activeAccount = this.authService.instance.getActiveAccount();
					const claims = activeAccount!.idTokenClaims;
					const rolesStr = claims!["extension_LsUserRoles"] as string;
					const userRole: LsUserRole = new LsUserRole(activeAccount!.localAccountId);
					userRole.roles = JSON.parse(rolesStr).map((role: any) => ({
						userRole: role.UserRole,
						companyId: role.CompanyId
					}));
					this.store.dispatch(AppActions.setUserRole({ userRole: userRole }));
				}
			});
		this.msalBroadcastService.msalSubject$
			.pipe(
				filter(
					(subject: EventMessage) =>
						subject.eventType === EventType.LOGIN_FAILURE && subject.error!.message.includes("REST-ValidateProfile")
				),
				takeUntil(this.componentTeardown$),
				map(() => {
					this.router.navigate(["onboarding/info"], { queryParams: { error: "Sign up failed" } });
				})
			)
			.subscribe();
	}

	ngOnInit() {
		if (this.featureFlagService.isEnabled(FeatureFlags.Onboarding_V1_0_1)) {
			this.initializeChatbot();
		}

		const account = this.authService.instance.getActiveAccount();
		if (account) {
			if (window.location.pathname === "/" || window.location.pathname.includes("onboarding/info")) {
				this.router.navigate(["onboarding/begin-application"]);
			}
		}
		if (environment.pendoEnabled) this.initializePendo(account ?? undefined);

		this.store
			.select(this.appSelectors.selectUserInfo)
			.pipe(
				filter((ui) => !!ui),
				takeUntil(this.componentTeardown$),
				map((ui) => {
					this.populatePrechatFields(ui!);
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	private initializePendo(account?: AccountInfo) {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.innerHTML = `(function(apiKey){
      (function(p,e,n,d,o){
        var v,w,x,y,z;o=p[d]=p[d]||{};
        o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];
        for(w=0,x=v.length;w<x;++w)(function(m){
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};
        })(v[w]);
        y=e.createElement(n);
        y.async=!0;
        y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y,z);
      })(window,document,'script','pendo');

      pendo.initialize({
        visitor: {
          id: '${account?.localAccountId}',
          email: '${account?.idTokenClaims!["email"]}',
        },
        account: {
          id: 'LIMESTONE-CLIENT-PORTAL-${environment.environmentName}',
        }
      });
    })('534db506-a887-4b7b-58de-5b1b58190115');`;

		const head = document.getElementsByTagName("head")[0];
		if (head !== null && head !== undefined) {
			document.head.appendChild(script);
		}
	}

	private initializeChatbot() {
		embedded_svc.settings.displayHelpButton = true; //Or false
		embedded_svc.settings.language = "en"; //For example, enter 'en' or 'en-US'

		//embedded_svc.settings.defaultMinimizedText = "Help"; //(Defaults to Chat with an Expert)
		//embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

		//embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
		//embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

		// Settings for Chat
		//embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
		// Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
		// Returns a valid button ID.
		//};

		//embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
		//embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

		embedded_svc.settings.enabledFeatures = ["LiveAgent"];
		embedded_svc.settings.entryFeature = "LiveAgent";

		embedded_svc.init(
			environment.salesforceChatbot.url,
			environment.salesforceChatbot.siteUrl,
			environment.salesforceChatbot.serviceUrl,
			environment.salesforceChatbot.id,
			environment.salesforceChatbot.eswLiveAgentDevName,
			environment.salesforceChatbot.config
		);
	}

	populatePrechatFields(user: UserInfo) {
		embedded_svc.settings.prepopulatedPrechatFields = {
			FirstName: user?.firstName,
			LastName: user?.lastName,
			Email: user?.emailAddress
		};

		embedded_svc.settings.extraPrechatInfo = [
			{
				entityName: "Contact",
				showOnCreate: true,
				linkToEntityName: "Case",
				linkToEntityField: "ContactId",
				saveToTranscript: "ContactId",
				entityFieldMaps: [
					{
						isExactMatch: true,
						fieldName: "Email",
						doCreate: true,
						doFind: true,
						label: "Email"
					}
				]
			},
			{
				entityName: "Case",
				showOnCreate: true,
				saveToTranscript: "CaseId",
				entityFieldMaps: [
					{
						isExactMatch: true,
						fieldName: "Email",
						doCreate: true,
						doFind: true,
						label: "Email"
					}
				]
			}
		];
	}
}
