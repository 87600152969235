import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { StepperActions, StepperSelectors } from "src/app/AppStateManagement";
import { IRouteStepData } from "src/app/Models/Interfaces";
import { Subject, filter, map, takeUntil } from "rxjs";
import { ActivatedRouteSnapshot, Params, Router, RoutesRecognized } from "@angular/router";

@Injectable()
export class RouteStepDataService implements OnDestroy {
	private activeRouteData?: IRouteStepData;
	private routeData?: Map<string, IRouteStepData>;
	private componentTeardown$ = new Subject();
	constructor(
		public store: Store,
		public router: Router,
		public stepperSelectors: StepperSelectors
	) {
		this.store
			.select(this.stepperSelectors.selectState)
			.pipe(
				filter((actState) => !!actState),
				takeUntil(this.componentTeardown$),
				map((state) => {
					this.activeRouteData = state.activeRouteStepData;
					this.routeData = state.routeStepData;
				})
			)
			.subscribe();

		this.router.events
			.pipe(
				takeUntil(this.componentTeardown$),
				filter((event: any) => event instanceof RoutesRecognized),
				map((event: RoutesRecognized) => {
					const key = this.getActiveRouteDataKey(event.state.root);
					const rd = this.routeData?.get(key);
					if (rd) this.store.dispatch(StepperActions.setActiveRouteData({ routeData: rd }));
				})
			)
			.subscribe();
	}
	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	getActiveRouteData(): IRouteStepData {
		return this.activeRouteData!;
	}

	navByConfig(index = 0, paramMap?: Map<string, string | number>, queryParams?: Params) {
		if (!this.routeData) throw new Error("Route data is missing.");
		if (index >= this.activeRouteData!.nextUrl!.length) throw new Error(`No route for index ${index}`);
		const path = this.activeRouteData!.nextUrl![index];
		this.router.navigate([this.updateUrlParams(path, paramMap)], { queryParams, onSameUrlNavigation: "reload" });
	}

	navByPath(url: string, paramMap?: Map<string, string | number>) {
		if (!this.routeData) throw new Error("Route data is missing.");
		const rd = this.routeData.get(url);
		if (!rd) throw new Error(`No route data for ${url}`);
		this.router.navigate([this.updateUrlParams(url, paramMap)], { replaceUrl: false, onSameUrlNavigation: "reload" });
	}

	private updateUrlParams(url: string, paramMap?: Map<string, string | number>): string {
		if (paramMap && paramMap?.size > 0) {
			paramMap.forEach((value, key) => {
				url = url!.replace(`:${key}`, `${value}`);
			});
		}
		return url;
	}

	getActiveRouteDataKey(activatedRoute: ActivatedRouteSnapshot): string {
		let currentRoute = activatedRoute.root;
		let url = currentRoute.routeConfig?.path ?? "";
		while (currentRoute.firstChild) {
			currentRoute = currentRoute.firstChild;
			if (!currentRoute || currentRoute.routeConfig?.path?.startsWith(":")) {
				continue;
			}
			url +=
				currentRoute.routeConfig?.path !== null && currentRoute.routeConfig?.path !== ""
					? "/" + currentRoute.routeConfig?.path
					: "";
		}
		return url ?? null;
	}
}
