import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, filter, forkJoin, map, Observable, take } from "rxjs";
import { AppSelectors, CompanyProfileActions, CompanyProfileSelectors } from "../../../AppStateManagement";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { RouteStepDataService } from "./route-step-data.service";

@Injectable({ providedIn: "root" })
export class CompanyResolverService {
	constructor(
		private store: Store<any>,
		private companyProfileSelectors: CompanyProfileSelectors,
		private appSelectors: AppSelectors,
		private routeStepDataService: RouteStepDataService
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		this.initPrefetchData(route);
		return forkJoin([this.companyProfile$, this.onboardCompanyProgress$]);
	}
	private companyProfile$ = this.store.select(this.companyProfileSelectors.selectCompanyProfile).pipe(
		filter((c) => !!c),
		take(1)
	);
	private onboardCompanyProgress$ = this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress).pipe(
		filter((progress) => !!progress),
		take(1)
	);
	private initPrefetchData(route: ActivatedRouteSnapshot): void {
		combineLatest([
			this.store.select(this.appSelectors.selectUserRoles),
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress)
		])
			.pipe(
				filter(([userRole]) => !!userRole && userRole.roles.length > 0),
				take(1),
				map(([userRole, cp, ocp]) => {
					const key = this.routeStepDataService.getActiveRouteDataKey(route);
					if (!cp && !ocp) {
						this.store.dispatch(CompanyProfileActions.getCompanyProfile({ companyId: userRole!.roles[0].companyId }));
						this.store.dispatch(
							CompanyProfileActions.getOnboardCompanyProgress({
								companyId: userRole!.roles[0].companyId,
								routeKey: key
							})
						);
					}
				})
			)
			.subscribe();
	}
}
