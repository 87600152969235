import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, switchMap } from "rxjs";
import { AppService } from "../../services";
import { AppActions } from "./app-actions";
import { HttpErrorService } from "src/services/HTTP";

@Injectable()
export class AppEffects {
	constructor(
		private actions$: Actions,
		private appService: AppService,
		private httpErrorService: HttpErrorService
	) {}

	fetchUserInfo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AppActions.getUserInfo),
			switchMap((info) => this.appService.getUserInfo().pipe(map((info) => AppActions.setUserInfo({ userInfo: info }))))
		)
	);

	updateUserInfo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AppActions.updateUserInfo),
			mergeMap((act) =>
				this.appService.updateUserInfo(act.userInfo).pipe(
					switchMap((userInfo) => [AppActions.setUserInfo({ userInfo }), AppActions.userInfoSaveSuccessful()]),
					catchError((err) => {
						console.error(err);
						return this.httpErrorService
							.parseError(err)
							.pipe(map((resp) => AppActions.userInfoSaveUnsuccessful({ errors: resp })));
					})
				)
			)
		)
	);

	fetchUserIP$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AppActions.getUserIP),
			switchMap((ip) =>
				this.appService.getUserRemoteIP().pipe(map((ip) => AppActions.setUserIP({ userIPAddress: ip })))
			)
		)
	);
}
